import(/* webpackMode: "eager" */ "/codebuild/output/src297824084/src/telegram-game-hub/apps/pinna/app/[lng]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src297824084/src/telegram-game-hub/apps/pinna/components/layout/FlowerHead.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src297824084/src/telegram-game-hub/apps/pinna/components/layout/Navigator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src297824084/src/telegram-game-hub/apps/pinna/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppClientProvider"] */ "/codebuild/output/src297824084/src/telegram-game-hub/apps/pinna/provider/appClientProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src297824084/src/telegram-game-hub/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src297824084/src/telegram-game-hub/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src297824084/src/telegram-game-hub/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src297824084/src/telegram-game-hub/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src297824084/src/telegram-game-hub/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src297824084/src/telegram-game-hub/node_modules/next/font/local/target.css?{\"path\":\"app/[lng]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src297824084/src/telegram-game-hub/node_modules/next/font/local/target.css?{\"path\":\"app/[lng]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\"}],\"variableName\":\"geistMono\"}");
